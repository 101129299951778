import { Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import LeftChevronIcon from '@mui/icons-material/ChevronLeft';
import PaidIcon from '@mui/icons-material/Paid';
import TransactionsIcon from '@mui/icons-material/ReceiptLong';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import WandIcon from '@mui/icons-material/AutoFixNormal';
import WelcomeIcon from '@mui/icons-material/Recommend';
import ContributeIcon from '@mui/icons-material/VolunteerActivism';
import ContributionsIcon from '@mui/icons-material/Favorite';

import { Link } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';
import { useContext } from 'react';
import { CzarContext } from '../CzarContext';
import useConfig from '../config';

export const drawerWidth = 240;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  minHeight: 64,
  justifyContent: 'flex-end',
}));

const NavDrawer = (props: { drawerOpen: boolean, toggleDrawerOpen: () => void, toggleCzarMode: () => void }) => {
  const { status, data } = useSigninCheck();
  const loggedIn = status === 'success' && data?.signedIn;
  const czarContext = useContext(CzarContext);
  const appConfig = useConfig();

  return (<Drawer
    variant='persistent'
    anchor='left'
    open={props.drawerOpen}
    sx={{
      width: 240,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: drawerWidth,
        boxSizing: 'border-box',
      },
    }}
  >
    <DrawerHeader>
      <IconButton onClick={props.toggleDrawerOpen}>
        <LeftChevronIcon />
      </IconButton>
    </DrawerHeader>
    <Divider />
    <List>
      {!loggedIn && <Link to='/welcome'>
        <ListItem button>
          <ListItemIcon>
            <WelcomeIcon />
          </ListItemIcon>
          <ListItemText primary='Welcome' />
        </ListItem>
      </Link>}
      {appConfig.grantsPageEnabled && loggedIn && <Link to='/grants'>
        <ListItem button key={'grants'}>
          <ListItemIcon>
            <PaidIcon />
          </ListItemIcon>
          <ListItemText primary={'Grants'} />
        </ListItem>
      </Link>
      }
      {loggedIn && <Link to='/transactions'>
        <ListItem button key={'transactions'}>
          <ListItemIcon>
            <TransactionsIcon />
          </ListItemIcon>
          <ListItemText primary={'Transactions'} />
        </ListItem>
      </Link>
      }
      { appConfig.contributePageEnabled && loggedIn && <Link to='/contribute'>
        <ListItem button key={'contribute'}>
            <ListItemIcon>
              <ContributeIcon />
            </ListItemIcon>
            <ListItemText primary={'Contribute'} />
          </ListItem>
        </Link>
      }
      {
        appConfig.contributionsPageEnabled && loggedIn && <Link to='/contributions'>
          <ListItem button key={'contributions'}>
            <ListItemIcon>
              <ContributionsIcon />
            </ListItemIcon>
            <ListItemText primary={ czarContext.inCzarMode ? 'Our Contributions' : 'My Contributions'} />
          </ListItem>
        </Link>
      }
    </List>
    <List sx={{ marginTop: 'auto' }}>
      {czarContext.isCzar &&
        <ListItem button key={'toggle-czar'} onClick={props.toggleCzarMode}>
          <ListItemIcon>
            <WandIcon />
          </ListItemIcon>
          <ListItemText primary={czarContext.inCzarMode ? 'Disable Czar Mode' : 'Enable Czar Mode'} />
        </ListItem>
      }
      <Link to='/auth'>
        <ListItem button key={'login'} sx={{ display: loggedIn ? 'none' : 'flex' }}>
          <ListItemIcon>
            <LoginIcon />
          </ListItemIcon>
          <ListItemText primary={'Log In'} />
        </ListItem>
      </Link>
      <Link to='/logout'>
        <ListItem button key={'logout'} sx={{ display: loggedIn ? 'flex' : 'none' }}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={'Log Out'} />
        </ListItem>
      </Link>
    </List>
  </Drawer>);
}

export default NavDrawer;