import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';
import { connectFirestoreEmulator, getFirestore } from '@firebase/firestore';
import { AuthProvider, FirebaseAppProvider, FirestoreProvider, FunctionsProvider } from 'reactfire';
import { StyledEngineProvider } from '@mui/styled-engine';
import { connectFunctionsEmulator, getFunctions } from '@firebase/functions';
import { connectAuthEmulator, getAuth } from '@firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCYVjmhQc39eweTfQHC89DRWaSAp6sOjUM",
  authDomain: "communism-37b06.firebaseapp.com",
  projectId: "communism-37b06",
  storageBucket: "communism-37b06.appspot.com",
  messagingSenderId: "496810707010",
  appId: "1:496810707010:web:62d6cfc4b03ae4e91685db"
};


const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp);
const firestore = getFirestore(firebaseApp);

if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(auth, 'http://localhost:9096');
  connectFunctionsEmulator(functions, 'localhost', 9097);
  connectFirestoreEmulator(firestore, 'localhost', 9098);
}

ReactDOM.render(
  <FirebaseAppProvider firebaseApp={firebaseApp}>
    <AuthProvider sdk={auth}>
      <FunctionsProvider sdk={functions}>
        <FirestoreProvider sdk={firestore}>
          <StyledEngineProvider injectFirst>
            <App />
          </StyledEngineProvider>
        </FirestoreProvider>
      </FunctionsProvider>
    </AuthProvider>
  </FirebaseAppProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
