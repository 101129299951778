import { httpsCallable } from '@firebase/functions';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useState } from 'react';
import { useFunctions } from 'reactfire';
import { IContribution } from '../models/Contribution';

export interface IRefundContributionDialogProps {
  contribution: IContribution;
  open: boolean;
  onClose: () => void;
}

const RefundContributionDialog = (props: IRefundContributionDialogProps) => {
  const [fullRefund, setFullRefund] = useState(true);
  const [partialAmount, setPartialAmount] = useState(props.contribution.amount);
  const [submitting, setSubmitting] = useState(false);
  const [complete, setComplete] = useState(false);
  const [errorText, setErrorText] = useState('');
  const functions = useFunctions();
  const requestRefund = httpsCallable<{contributionId: string, full: boolean, amount: number}, {success: boolean}>(functions, 'requestRefund');
  const doRefund = () => {
    setSubmitting(true);
    const refundAmount = fullRefund ? props.contribution.amount : partialAmount;
    requestRefund({contributionId: props.contribution.id, full: fullRefund, amount: refundAmount})
      .then((result) => {
        setSubmitting(false);
        setComplete(true);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.message && error.message !== 'INTERNAL') {
          setErrorText(error.message);
        } else {
          setErrorText('Internal Error');
        }
      });
  }
  if (complete) {
    return (
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Refund Complete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your refund has been processed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Done</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Refund</DialogTitle>
      <DialogContent>
        {errorText !== '' && <DialogContentText sx={{color: 'red'}}>{errorText}</DialogContentText>}
        <DialogContentText>
          You are refunding your contribution of ${props.contribution.amount.toFixed(2)}.
        </DialogContentText>
        <RadioGroup defaultValue={true}>
          <FormControlLabel control={<Radio onClick={() => setFullRefund(true)} />} label="Refund my full contribution" value={true} />
          <FormControlLabel control={<Radio onClick={() => setFullRefund(false)} />} label="Refund part of my contribution" value={false} />
        </RadioGroup>
        {!fullRefund &&
          <TextField
            label="Refund amount"
            type="number"
            inputProps={{step: 0.01, min: 0.01, max: props.contribution.amount}}
            fullWidth
            value={partialAmount}
            onChange={(evt) => setPartialAmount(Number(evt.target.value))} />
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} disabled={submitting}>Cancel</Button>
        <Button onClick={doRefund} disabled={submitting}>Refund</Button>
      </DialogActions>
    </Dialog>
  )
}

export default RefundContributionDialog;