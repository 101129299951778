import { 
  Paper,
  Typography,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  FormLabel,
  RadioGroup,
  Radio } from '@mui/material';
import { useSigninCheck, useFunctions } from 'reactfire';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { httpsCallable } from '@firebase/functions';

type ContributePaymentMethod = 'stripe';

interface IContributeForm {
  amount: number;
  attribution: boolean;
  method: ContributePaymentMethod;
}

const Contribute = () => {
  const signinCheck = useSigninCheck();
  const functions = useFunctions();
  const shouldRedirectForLogin = (signinCheck.status === 'success' && signinCheck.data?.signedIn === false);
  const createContribution = httpsCallable<IContributeForm, {url: string}>(functions, 'createContribution');
  const [values, setValues] = useState<IContributeForm>({ amount: 5, attribution: true, method: 'stripe' });
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [attributionText, setAttributionText] = useState('');

  useEffect(() => {
    if (signinCheck.status === 'success') {
      signinCheck.data.user?.getIdTokenResult().then((idTokenResult) => {
        setAttributionText(`Contribution from ${idTokenResult.claims.displayName}`);
      });
    }
  }, [signinCheck.status, signinCheck.data?.user]);

  useEffect(() => {
    if (submitting) {
      createContribution(values).then((result) => {
        window.location.href = result.data.url;
      });
    }
  })

  if (shouldRedirectForLogin) {
    return <Redirect to='/' />;
  }

  const handleFormChange = (prop: keyof IContributeForm) => (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (prop) {
      case 'amount':
        let amount = Number(event.target.value);
        setValues({ ...values, amount });
        break;
      case 'attribution':
        setValues({ ...values, attribution: event.target.checked });
        break;
      case 'method':
        setValues({ ...values, method: event.target.value as ContributePaymentMethod });
        break;
      default:
        break;
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement> | undefined) => {
    if (event) {
      event.preventDefault();
    }

    setSubmitting(true);
  }

  return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <div style={{width: 600}}>
      <Paper elevation={2}>
        <Typography variant="h4" sx={{textAlign: 'center'}}>Contribute</Typography>
        <Typography variant="body1" sx={{ml: 1, mr: 1, mt: 1}}>
          Thank you for contributing to the Scrumptious Yeets! Your contribution will be deposited into the group bank account.
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControl component='fieldset' sx={{m: 2}}>
            <FormLabel component='legend'>Payment Method</FormLabel>
            <RadioGroup row>
              <FormControlLabel value='stripe' control={<Radio checked />} label='Credit Card' />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{m: 1, pr: 2}} variant='filled' fullWidth>
            <InputLabel htmlFor='contrib-amount'>Amount</InputLabel>
            <FilledInput
              id='contrib-amount'
              value={values.amount}
              onChange={handleFormChange('amount')}
              startAdornment={<InputAdornment position='start'>$</InputAdornment>}
              type='number'
              inputProps={{step: 0.01, min: 0.01, max: 100}} />
          </FormControl>
          <FormGroup sx={{m: 1}}>
            <FormControlLabel control={<Switch value={values.attribution} onChange={handleFormChange('attribution')} defaultChecked />} label="Attribution" />
            <Typography variant='caption'>
              {values.attribution ?
              `Members will see this transaction as "${attributionText}" on the Transactions page`
              : 'Members will see this transaction as "Private Transaction" on the Transactions page'}
            </Typography>
          </FormGroup>
          <FormControl sx={{m: 1}}>
            <Button type='submit' disabled={submitting}>Submit</Button>
          </FormControl>
        </form>
      </Paper>
    </div>
  </div>;
}

export default Contribute;