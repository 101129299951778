import { useEffect, useState } from 'react';
import './App.css';
import { 
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from 'react-router-dom';
import Grants from './pages/Grants';
import { AppBar, CssBaseline, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, styled } from '@mui/system';
import NavDrawer, { DrawerHeader, drawerWidth } from './components/NavDrawer';
import Transactions from './pages/Transactions';
import AuthHandler from './components/AuthHandler';
import LogoutHandler from './components/LogoutHandler';
import { CzarContext } from './CzarContext';
import { useSigninCheck } from 'reactfire';
import Welcome from './pages/Welcome';
import Contribute from './pages/Contribute';
import Contributions from './pages/Contributions';

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginLeft: `-${drawerWidth}px`,
}));

function App() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [czarContext, setCzarContext] = useState({isCzar: false, inCzarMode: false});
  const { status: signinStatus, data: signinData } = useSigninCheck();

  useEffect(() => {
    if (signinStatus === 'success') {
      if (signinData?.signedIn) {
        signinData.user.getIdTokenResult().then(token => {
          if (!token.claims.czar && czarContext.isCzar) {
            setCzarContext({isCzar: false, inCzarMode: false});
          } else if(token.claims.czar && !czarContext.isCzar) {
            setCzarContext({isCzar: true, inCzarMode: false});
          }
        });
      } else {
        setCzarContext({isCzar: false, inCzarMode: false});
      }
    }
  }, [signinStatus, signinData?.signedIn, czarContext.isCzar, signinData?.user]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  }

  const toggleCzarMode = () => {
    let newContext = {...czarContext};
    newContext.inCzarMode = !czarContext.inCzarMode;
    setCzarContext(newContext);
  }

  return (
    <CzarContext.Provider value={czarContext}>
      <Router>
        <Box sx={{display: 'flex'}}>
          <CssBaseline />
          <AppBar position='fixed' sx={drawerOpen ? {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
          } : {}}>
            <Toolbar>
              <IconButton edge='start' color='inherit' onClick={toggleDrawer} sx={drawerOpen ? {display: 'none', mr: 2} : {mr: 2}}>
                <MenuIcon />
              </IconButton>
              <Typography variant='h6' noWrap component='div'>
                Communism Management System
              </Typography>
            </Toolbar>
          </AppBar>
          <NavDrawer drawerOpen={drawerOpen} toggleDrawerOpen={toggleDrawer} toggleCzarMode={toggleCzarMode} />
          <Main style={drawerOpen ? {marginLeft: 0} : {}}>
            <DrawerHeader />
            <Switch>
              <Route path='/auth'>
                <AuthHandler />
              </Route>
              <Route path='/logout'>
                <LogoutHandler />
              </Route>
              <Route path='/grants'>
                <Grants />
              </Route>
              <Route path='/transactions'>
                <Transactions />
              </Route>
              <Route path='/contribute'>
                <Contribute />
              </Route>
              <Route path='/contributions'>
                <Contributions />
              </Route>
              <Route path='/welcome'>
                <Welcome />
              </Route>
              <Route path='/'>
                <Redirect to='/welcome' />
              </Route>
            </Switch>
          </Main>
        </Box>
      </Router>
    </CzarContext.Provider>
  );
}

export default App;
