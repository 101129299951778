import { collection, CollectionReference, orderBy, query, where } from '@firebase/firestore';
import { DataGrid, GridActionsCellItem, GridActionsColDef, GridColDef, GridRowParams, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Redirect } from 'react-router';
import { useFirestore, useFirestoreCollectionData, useSigninCheck } from 'reactfire';
import { IContribution } from '../models/Contribution';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RefundIcon from '@mui/icons-material/Undo';
import { useMemo, useState } from 'react';
import RefundContributionDialog from '../components/RefundContributionDialog';

const Contributions = () => {
  const signinCheck = useSigninCheck();
  const shouldRedirectForLogin = (signinCheck.status === 'success' && signinCheck.data?.signedIn === false);
  const firestore = useFirestore();
  const contributionCollection = collection(firestore, 'contributions') as CollectionReference<IContribution>;
  const contributionQuery = query(contributionCollection, where('userId', '==', signinCheck.data?.user?.uid || ''), orderBy('timestamp', 'desc'));
  const [refundDialogOpen, setRefundDialogOpen] = useState(false);
  const [refundContribution, setRefundContribution] = useState<IContribution | null>(null);

  const contributionQueryResult = useFirestoreCollectionData(contributionQuery, { idField: 'id' });
  const rows = contributionQueryResult.data || [];
  const refundCutoff = useMemo(() => {
    let ret = new Date();
    ret.setDate(ret.getDate() - 7);
    return ret;
  }, []);

  const OpenReceipt = (params: GridRowParams) => {
    let receiptUrl = params.getValue(params.id, 'receiptUrl');
    if (receiptUrl && typeof(receiptUrl) === 'string') {
      window.open(receiptUrl, '_blank');
    }
  }

  const RefundContribution = (params: GridRowParams) => {
    setRefundContribution(params.row as IContribution);
    setRefundDialogOpen(true);
  }

  const columns: Array<GridColDef | GridActionsColDef> = [
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 175,
      valueGetter(params: GridValueGetterParams) {
        return new Date(params.value.seconds * 1000);
      },
      valueFormatter(params: GridValueFormatterParams) {
        return (params.value as Date).toLocaleString('en-US');
      }
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueFormatter: (params: GridValueFormatterParams) => {
        return `${params.value! < 0 ? '-' : ''}$${(Math.abs(params.value as number)).toFixed(2)}`;
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      valueGetter(params: GridValueGetterParams) {
        return params.row.refunded ? 'Refunded' : 'Complete';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      align: 'left',
      getActions: (params: GridRowParams) => {
        let actions = [
          <GridActionsCellItem icon={<ReceiptIcon />} onClick={() => OpenReceipt(params)} label='View Receipt' />
        ];
        if (params.getValue(params.id, 'timestamp')! > refundCutoff && params.getValue(params.id, 'refunded') === false) {
          actions.push(
            <GridActionsCellItem icon={<RefundIcon />} onClick={() => RefundContribution(params)} label='Refund' />
          );
        }
        return actions;
      }
    }
  ]

  if (shouldRedirectForLogin) {
    return <Redirect to='/' />;
  }

  return (
    <div>
      {refundContribution && <RefundContributionDialog open={refundDialogOpen} contribution={refundContribution!} onClose={() => setRefundDialogOpen(false)} />}
      <div style={{display: 'flex', height: '100%'}}>
        <div style={{flexGrow: 1}}>
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight={true}
            pageSize={12}
            loading={contributionQueryResult.status !== 'success'}
            hideFooterSelectedRowCount={true} />
        </div>
      </div>
    </div>
  );
}

export default Contributions;