import { collection, CollectionReference, getDocs, orderBy, query, where } from '@firebase/firestore';
import { CircularProgress } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router';
import { useFirestore, useFirestoreCollectionData, useSigninCheck } from 'reactfire';
import { CzarContext } from '../CzarContext';
import IAccount from '../models/Account';
import ITransactionInfo from '../models/Transaction';
import ITransaction from '../models/Transaction';

import './Transactions.css'

const Transactions = () => {
  const signinCheck = useSigninCheck();
  const shouldRedirectForLogin = (signinCheck.status === 'success' && signinCheck.data?.signedIn === false);
  const czarContext = useContext(CzarContext);
  const firestore = useFirestore();
  const transactionCollection = collection(firestore, 'transactions') as CollectionReference<ITransaction>;
  const [detailRows, setDetailRows] = useState<{[id: string]: ITransactionInfo}>({});

  const oneYearAgo = useMemo(() => {
    let ret = new Date();
    ret.setFullYear(ret.getFullYear() - 1);
    return ret;
  }, []);
  
  const txQueryParts = [
    where('date', '>', oneYearAgo),
    orderBy('date', 'desc')
  ]

  const transactionQuery = query(transactionCollection, ...txQueryParts, orderBy('amount', 'desc'));

  const transactionResult = useFirestoreCollectionData(transactionQuery, {
    idField: 'id'
  });

  const { status: accountsStatus, data: accounts } = useFirestoreCollectionData(query(collection(firestore, 'accounts') as CollectionReference<IAccount>), {
    idField: 'id'
  });

  useEffect(() => {
    if (czarContext.inCzarMode) {
      const transactionDetailsCollection = collection(firestore, 'transactionDetails');
      const transactionDetailsQuery = query(transactionDetailsCollection, ...txQueryParts);
      getDocs(transactionDetailsQuery).then(transactionDetails => {
        let map: { [id: string]: ITransactionInfo } = {};
        transactionDetails.forEach(qds => map[qds.id] = qds.data() as ITransactionInfo);
        console.log(map);
        setDetailRows(map);
      });
    }
  }, [czarContext.inCzarMode]);

  if (shouldRedirectForLogin) {
    return <Redirect to='/' />;
  }

  
  if (accountsStatus === 'loading') {
    return <CircularProgress />;
  }

  let accountMap: {[x: string]: IAccount} = {};
  for (let i = 0; i < accounts.length; i++) {
    accountMap[accounts[i].id] = accounts[i];
  }

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      valueFormatter(params: GridValueFormatterParams) {
        return (params.value as Date).toLocaleDateString('en-US', {timeZone: 'UTC'});
      }
    },
    {
      field: 'account',
      headerName: 'Account',
      width: 150
    },
    {
      field: 'description',
      flex: 1,
      headerName: 'Description'
    },
    
    {
      field: 'amount',
      headerName: 'Amount',
      cellClassName: (params: GridCellParams<number>) => {
        return params.value < 0 ? 'tx-negative' : 'tx-positive';
      },
      valueFormatter: (params: GridValueFormatterParams) => {
        return `${params.value! < 0 ? '-' : ''}$${(Math.abs(params.value as number)).toFixed(2)}`;
      }
    }
  ]

  if (czarContext.inCzarMode) {
    columns.splice(3, 0, {
      field: 'rawDescription',
      headerName: 'Raw Description',
      flex: 1
    });
  }

  const rows = (transactionResult.data || []).map(transaction => {
    let description = transaction.description;
    if (transaction.private && czarContext.inCzarMode) {
      description = '(...)';
      if (detailRows[transaction.id] && detailRows[transaction.id].description) {
        description = detailRows[transaction.id].description;
      }
    }
    return {
      id: transaction.id,
      date: new Date(transaction.date.seconds * 1000),
      account: accountMap[transaction.account.id]?.publicName ?? '...',
      amount: transaction.amount,
      description,
      rawDescription: czarContext.inCzarMode && detailRows[transaction.id] ? detailRows[transaction.id].rawDescription : undefined
    }
  });

  return (
    <div style={{display: 'flex', height: '100%'}}>
      <div style={{flexGrow: 1}}>
        <DataGrid
            rows={rows}
            columns={columns}
            autoHeight={true}
            pageSize={12}
            loading={transactionResult.status !== 'success'}
            hideFooterSelectedRowCount={true} />
      </div>
    </div>
  );
}

export default Transactions;