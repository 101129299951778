import { collection, CollectionReference, orderBy, query, where } from '@firebase/firestore';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useContext } from 'react';
import { Redirect } from 'react-router';
import { useFirestore, useFirestoreCollectionData, useSigninCheck } from 'reactfire';
import { CzarContext } from '../CzarContext';
import IGrant from '../models/Grant';

const Grants = () => {
  const signinCheck = useSigninCheck();
  const shouldRedirectForLogin = (signinCheck.status === 'success' && signinCheck.data?.signedIn === false);
  const firestore = useFirestore();
  const czarContext = useContext(CzarContext);
  const grantsCollection = collection(firestore, 'grants') as CollectionReference<IGrant>;
  const queryParams = [];
  if (!czarContext.inCzarMode) {
    queryParams.push(where('private', '!=', true));
    queryParams.push(orderBy('private', 'asc'));
  }

  queryParams.push(orderBy('createdAt', 'desc'));

  const grantsQuery = query(grantsCollection, ...queryParams);

  const { status, data: grants } = useFirestoreCollectionData(grantsQuery, {
    idField: 'id'
  });

  if (shouldRedirectForLogin) {
    return <Redirect to='/' />;
  }

  if (status === 'loading') {
    return <CircularProgress />;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Grant ID</TableCell>
            <TableCell>Summary</TableCell>
            <TableCell>Amount</TableCell>
            {czarContext.inCzarMode && <TableCell>Private</TableCell>}
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {grants.map(grant => (
            <TableRow key={grant.id}>
              <TableCell>{grant.id}</TableCell>
              <TableCell>{grant.summary}</TableCell>
              <TableCell>{grant.amount}</TableCell>
              {czarContext.inCzarMode && <TableCell>{grant.private ? 'Yes' : 'No'}</TableCell>}
              <TableCell>{grant.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
  );
}

export default Grants;