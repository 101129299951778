import { doc, DocumentReference, DocumentSnapshot } from '@firebase/firestore';
import { ObservableStatus, useFirestore, useFirestoreDoc } from 'reactfire';

export interface IAppConfig {
  grantsPageEnabled: boolean;
  contributePageEnabled: boolean;
  contributionsPageEnabled: boolean;
}

const defaultConfig: IAppConfig = {
  grantsPageEnabled: false,
  contributePageEnabled: false,
  contributionsPageEnabled: false,
}

const useConfig = (): IAppConfig => {
  const firestore = useFirestore();
  const configDoc = doc(firestore, 'config/default') as DocumentReference<IAppConfig>;
  let config: ObservableStatus<DocumentSnapshot<IAppConfig>> = useFirestoreDoc<IAppConfig>(configDoc);
  
  if (config?.status !== 'success') {
    return defaultConfig;
  }

  return config?.data?.data() ?? defaultConfig;
};

export default useConfig;