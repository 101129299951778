import React from 'react';

interface ICzarContext {
  isCzar: boolean;
  inCzarMode: boolean;
}

export const CzarContext = React.createContext<ICzarContext>({
  isCzar: false,
  inCzarMode: false
});
