import { signInWithCustomToken } from '@firebase/auth';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useAuth } from 'reactfire';
import { useDiscordAuth } from '../discordAuth';

function parseQueryString() {
  let searchString = window.location.search;
  let ret: {[x: string]: any} = {};
  if (searchString.length > 0) {
    searchString = searchString.substr(1);
    searchString.split('&').forEach(param => {
      const parts = param.split('=');
      const key = parts[0];
      const value = parts[1];
      ret[key] = value;
    });
    return ret;
  } else {
    return;
  }
}

const AuthHandler = () => {
  const [errorState, setErrorState] = useState('');
  const [doRedirect, setDoRedirect] = useState(false);
  const discordAuth = useDiscordAuth();
  const firebaseAuth = useAuth();
  const queryString = parseQueryString();

  function errorTryAgain() {
    window.location.replace('/auth');
  }

  function errorCancel() {
    window.location.replace('/');
  }

  useEffect(() => {
    if (queryString && queryString.error) {
      setErrorState(`Discord authentication error: "${(queryString.error_description.replace(/\+/g, ' ') || queryString.error)}"`);
      return;
    }
    if (queryString && queryString.code && errorState === '') {
      discordAuth.exchangeDiscordCode({code: queryString.code})
        .then(response => {
          if (response.data.token) {
            signInWithCustomToken(firebaseAuth, response.data.token).then(() => {
              setDoRedirect(true);
            });
          } else {
            setErrorState(response.data.error || 'Unknown error');
          }
        });
    } else {
      if (errorState === '') {
        discordAuth.beginDiscordAuth().then(response => window.location.href = response.data.url).catch(error => console.error(error));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (doRedirect) {
    return <Redirect to='/transactions' />
  }

  if (errorState !== '') {
    return <Dialog open={true}>
      <DialogTitle>Authentication Error</DialogTitle>
      <DialogContent>
        <DialogContentText>{errorState}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={errorTryAgain}>Try Again</Button>
        <Button onClick={errorCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  }

  return <span />
}

export default AuthHandler;