import { httpsCallable } from '@firebase/functions';
import { useFunctions } from 'reactfire';

export function useDiscordAuth() {
  const functions = useFunctions();
  
  const beginDiscordAuth = httpsCallable<unknown, {url: string}>(functions, 'beginDiscordAuth');
  const exchangeDiscordCode = httpsCallable<{code: string}, {token?: string, error?: string}>(functions, 'exchangeDiscordCode');

  return {
    beginDiscordAuth,
    exchangeDiscordCode
  }
}