import { Typography } from '@mui/material';

const Welcome = () => {
  return (
    <div className="welcome">
      <Typography variant="h2">Welcome!</Typography>
      <Typography variant="body1">
        Welcome to the Communism Management System! If you are a member (Regular or above) of the Scrumptious Yeets community, you can log in using the button in the bottom left.
      </Typography>
    </div>
  )
}

export default Welcome;